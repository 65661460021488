<template>
  <div class=" h-100-vh">
    <div class="row">
      <div class="col-12 col-lg-12 col-xl-6">
        <div class="card card-ecart ">
          <div class="card-header">
            <h5 v-text="$t('dashboard.questions.title')"></h5>
          </div>
          <div class="card-body">
            <ProductQuestions />
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-12 col-xl-6">
        <div class="card card-ecart ">
          <div class="card-header">
            <h5 v-text="$t('dashboard.menu.reviews')"></h5>
          </div>
          <div class="card-body">
            <ProductsReviews />
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex';
  import ProductsReviews from "@/components/dashboard/product/product-reviews";
  import ProductQuestions from "@/components/dashboard/product/product-questions";
  export default {
    components: {
      ProductsReviews,
      ProductQuestions,
    },
    data() {
      return ({
        message: null,
        exampleList: [{
          question: 'Buenas tardes, disculpe, deseo 5 sillas en color negro, nada mas no entendi como realizar la compra, se hace a través de agregar al carrito una por una o tengo que ir comprando de 1 en 1? gracias',
          name: 'Carla',
          qualification: 3,
          answer: null,
          product_id: 'rrrrfrfrfrfrfrf',
          time: 'Hace 7 horas',
          answerExample: 'Hola, buenas tardes!, gracias por consultarnos. Lamentablemente no lo incluye. En la pagina oficial del fabricante (pololu) puede encontrar ejemplos o guías de como programarlo, cualquier otra duda o comentario estamos a sus órdenes, saludos',
        }, {
          question: 'Disculpa amigo manejaras algún modelo con soporté de carga superior a las 150kg? Saludos',
          name: 'Alberto',
          qualification: 4,
          answer: null,
          product_id: 'rrrrfrfrfrfrfrf',
          time: 'Hace 18 horas',
          answerExample: 'Hola, buenas tardes, gracias por comentarnos, sí tenemos disponible este Robot Mini Zumo 32u4 Pololu Ensamblado Con Motores 75:1 Hp, cualquier duda o comentario, estamos a sus órdenes, saludos',
        }, {
          question: 'Disculpa tienes local en la cdmx ? Estoy interesado en el mini zumo 32U4 pero el de más torque 100:1 cual sería el costo de ese ?',
          name: 'Ricardo',
          qualification: 5,
          answer: null,
          product_id: 'rrrrfrfrfrfrfrf',
          time: 'Hace 3 horas',
          answerExample: 'Hola, buenas tardes, el modelo que tenemos cuenta con los motores de 75:1, pero tenemos los motorreductores de 100:1 320rpm, si gusta puede adquirir el Robot, mas los dos motorredutores 100:1, al final adjunto el link, gracias por comentarnos, nosotros tenemos tienda física ubicada en Salamanca Gto, con horario corrido de Lunes a Viernes de 10:00 Am a 8:00 Pm, Sabados de 10:00 Am a 2:00 Pm, lamentablemente no podemos escribir nuestros datos de contacto por este medio, cualquier duda o comentario, estamos a sus órdenes, saludos!.',
        }, {
          question: 'disculpa solo necesito la placa y a demás sensores pues ya cuento con el chasis ... también quiero saber si pueden venderme la programación para uso como mini',
          name: 'Angel',
          qualification: 3,
          answer: null,
          product_id: 'rrrrfrfrfrfrfrf',
          time: 'Hace 23 horas',
          answerExample: 'Hola, buenas tardes, gracias por comentarnos, lamentablemente no podemos venderle la Placa Electrónica, solo los tenemos completos, en la pagina del fabricante puede encontrar algunos códigos ejemplos, basta con buscarlo con el numero de item, 3126, quedamos a sus órdenes, saludos',
        }, {
          question: 'Te lo mandan armado o lo tengo que armar?',
          name: 'Elver',
          qualification: 1,
          answer: null,
          product_id: 'rrrrfrfrfrfrfrf',
          time: '12/12/2020',
          answerExample: 'Hola, buenos días, gracias por comentarnos, este modelo en específico ya viene armado de fábrica, listo para comenzar a programar, si gusta podemos crear una publicación especial con el material y las unidades adicionales que requiera con envío a domicilio gratis, cualquier duda o comentario, estamos a sus órdenes, saludos',
        }],
        product: {
          "id": "5fc670af802a580004639a3e",
          "ecommerce": "MercadoLibre",
          "ecart_id": "PD81012008",
          "object_id": "MLM801707313",
          "user_id": "5fab3b20b40b0a613e1b6f15",
          "tags": ["horno", "tostador"],
          "ranking": 17.5,
          "sell_out_stock": false,
          "sku": "MLM801707313",
          "name": "Horno Tostador Hamilton Beach Puerta Deslizante 19 Litros",
          "description": "<p>El mejor horno del mundo</p>",
          "currency": "MXN",
          "productType": "gold_pro",
          "quantity": 10,
          "price": 3201.28,
          "tax": 0,
          "category": {
            "id": "5e53fe45194caa0499d3c80b",
            "name": "Horno de pan",
            "category_id": 732,
            "translation": [{
              "id": "5e53fba1194caa0499d2e46f",
              "name": "Breadmakers",
              "category_id": 732
            }]
          },
          "options": [],
          "images": [{
            "id": "955777-MLM43028380139_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/955777-MLM43028380139_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/955777-MLM43028380139_082020"
          }, {
            "id": "625325-MLM43028380143_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/625325-MLM43028380143_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/625325-MLM43028380143_082020"
          }, {
            "id": "990841-MLM43028380145_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/990841-MLM43028380145_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/990841-MLM43028380145_082020"
          }, {
            "id": "831152-MLM43028380138_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/831152-MLM43028380138_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/831152-MLM43028380138_082020"
          }, {
            "id": "710641-MLM43028380137_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/710641-MLM43028380137_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/710641-MLM43028380137_082020"
          }, {
            "id": "761346-MLM43028380140_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/761346-MLM43028380140_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/761346-MLM43028380140_082020"
          }, {
            "id": "959230-MLM43028380141_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/959230-MLM43028380141_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/959230-MLM43028380141_082020"
          }, {
            "id": "967082-MLM43028380144_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/967082-MLM43028380144_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/967082-MLM43028380144_082020"
          }, {
            "id": "850812-MLM43028380142_082020",
            "variantIds": [],
            "url": "https://mlm-s2-p.mlstatic.com/850812-MLM43028380142_082020-O.jpg",
            "ecartapiUrl": "https://api.ecartapi.com/api/v2/products/MLM801707313/images/850812-MLM43028380142_082020"
          }, {
            "id": "IM44718543",
            "variantIds": [],
            "fromecart": true,
            "url": "https://ecart-prod.s3.amazonaws.com/products/5fc670af802a580004639a3e/IM44718543.jpg"
          }],
          "imageUrl": "https://mlm-s2-p.mlstatic.com/955777-MLM43028380139_082020-O.jpg",
          "condition": "new",
          "vendor": null,
          "store_id": "5fc670900f5dc645696edc5b",
          "store_name": "Importamatica",
          "dimensions": {
            "width": 50,
            "height": 50,
            "length": 50,
            "weight": 2
          },
          "units": {
            "width": "cm",
            "height": "cm",
            "length": "cm",
            "weight": "kg"
          },
          "variants": [],
          "active": true,
          "discounts": [],
          "commission": 8.5,
          "completed": 90,
          "language": "spanish",
          "translations": [],
          "translation": {
            "category": {
              "id": "5e53fba1194caa0499d2e46f",
              "name": "Breadmakers",
              "category_id": 732
            }
          },
          "google": false,
          "sales": 0,
          "views": 0,
          "tips": ["tags"],
          "preparation_time": "2",
          "slug": "Horno-Tostador-Hamilton-Beach-Puerta-Deslizante-19-Litros/5fc670af802a580004639a3e"
        }
      })
    },
    computed: {
      // ...mapState('product', ['modals'])
    },
    methods: {
      // ...mapMutations('product', ['changeModal']),
    },
    beforeMount() {
    }
  }
</script>